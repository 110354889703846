<template>
  <div class="promocode">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Promocode List</p>
      </b-col>
      <b-col cols="8">
        <div class="d-flex">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.keyword"
                placeholder="Promocode"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="searchPromocode"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
                @click="searchPromocode"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <b-button class="btn-filter ml-2" @click="openSideBar">
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button>

          <b-button
            class="btn-filter ml-2"
            @click="goPromocodeDetail(listCreate[0])"
          >
            Create</b-button
          >

          <!-- open when more than one condition -->
          <!-- <b-dropdown text="Create" right class="ml-2">
            <b-dropdown-item
              v-for="(item, index) in listCreate"
              :key="index"
              @click="goPromocodeDetail(item)"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown> -->
          <!-- open when more than one condition -->
        </div>
      </b-col>
    </b-row>

    <SideBarFilter
      :filter="filter"
      ref="sidebarPromocode"
      placeholder="Promocode"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <InputText
            v-model="filter.keyword"
            textFloat="Search"
            placeholder="Promocode"
            type="text"
            name="text"
          />
        </div>
        <div class="my-3">
          <!-- <AutoComplete
            ref="autoCompleteEventId"
            v-model="filter.filter.eventId"
            id="eventId"
            label="Event"
            placeholder="Event"
            dataListID="dataListID"
            valueField="eventId"
            showValue=""
            :optionsList="optionsEvent"
            @input="handleInput"
          /> -->
          <AutocompleteCustom
            v-model="filter.filter.eventId"
            ref="autoCompleteEventId"
            id="eventId"
            label="Event"
            placeholder="Event"
            dataListID="dataListID"
            valueField="eventId"
            showValue=""
            :optionsList="optionsEvent"
            @handleInput="handleInput"
            absolute
          />
        </div>
        <div class="my-3">
          <!-- v-model="newData.conditionType"
            :options="options" -->
          <InputSelect
            v-model="filter.filter.promocodeType"
            :options="optionsType"
            title="Promocode type"
            valueField="id"
            textField="name"
          />
        </div>
        <div class="my-3">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">Start Date</div>
            <div :class="['input-container']">
              <datetime
                v-model="filter.filter.startDateEndDate.startTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="startDate"
              >
                <!-- :max-datetime="maxDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="my-3">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">End Date</div>
            <div :class="['input-container']">
              <datetime
                v-model="filter.filter.startDateEndDate.endTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="endTime"
                :disabled="!filter.filter.startDateEndDate.startTime"
              >
                <!-- :max-datetime="maxDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endTime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="my-3">
          <InputSelect
            v-model="filter.filter.isActive"
            :options="optionsActive"
            id="adtiveSwitch"
            title="Status"
            valueField="value"
            textField="text"
          />
        </div>
      </template>
    </SideBarFilter>
    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
      @successSwitch="successSwitch"
    />

    <ModalAlertText :textModal="textModal" :arrModal="[]" />
  </div>
</template>

<script>
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";

import Table from "./component/Table.vue";
export default {
  components: {
    Table,
    ModalAlertText,
    AutocompleteCustom,
  },
  data() {
    return {
      filter: {
        filter: {
          eventId: null,
          promocodeType: null,
          startDateEndDate: {
            startTime: "",
            endTime: "",
          },
          isActive: null,
        },
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },

      listCreate: [],
      optionsType: [],
      optionsEvent: [],

      optionsActive: [
        {
          value: null,
          text: "All",
        },
        {
          value: 0,
          text: "Inactive",
        },
        {
          value: 1,
          text: "Active",
        },
      ],

      // state table
      items: [],

      fields: [
        {
          key: "id",
          label: "ID",
          class: "w-50px text-nowrap",
        },
        {
          key: "name",
          label: "Promocode name",
          class: "w-50 text-nowrap",
        },
        {
          key: "prefix",
          label: "Code / Prefix",
          class: "w-100px text-nowrap",
        },
        {
          key: "eventName",
          label: "Branch Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "promocodeTypeName",
          label: "Promocode Type",
          class: "w-100px text-nowrap",
        },
        {
          key: "startDate",
          label: "Start Time",
          class: "w-100px text-nowrap",
        },
        {
          key: "endDate",
          label: "End Time",
          class: "w-100px text-nowrap",
        },
        {
          key: "isActive",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action" },
      ],

      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state modal
      textModal: "",

      // state css
      iconSearch: false,

      // state toggle
      isSearchEvent: null,

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  mounted() {
    this.searchPromocode();
    this.getOptionPromocodeType();
  },
  methods: {
    async searchPromocode() {
      let { keyword, page, skip, take } = this.filter;

      let newConfigFilter = { ...this.filter.filter };

      this.filter.filter.eventId == "" && (newConfigFilter.eventId = null);
      this.filter.filter.promocodeType == 0 &&
        (newConfigFilter.promocodeType = null);

      // time
      if (!this.filter.filter.startDateEndDate.startTime) {
        newConfigFilter.startDateEndDate = null;
      }

      let finalFilter = {
        filter: newConfigFilter,
        keyword,
        page,
        skip,
        take,
      };

      const getData = await this.$services.promocode.searchPromocode(
        finalFilter
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },
    async getOptionPromocodeType() {
      const getData = await this.$services.master.getOptionPromocodeType();

      if (getData.result == 1) {
        this.listCreate = getData.detail;
        this.optionsType = [{ id: null, name: "All" }, ...getData.detail];
      }
    },

    async getEventOption(keyword) {
      // if (!keyword) {
      //   return;
      // }
      const check = Number(keyword) ? true : false;

      if (check) {
        return;
      }

      const getData = await this.$services.master.getEventOption(keyword);

      if (getData.result == 1) {
        this.optionsEvent = getData.detail;
      }
    },

    handleInput(val) {
      if (this.isSearchEvent) {
        clearTimeout(this.isSearchEvent);
      }

      this.isSearchEvent = setTimeout(() => {
        this.getEventOption(val);
      }, 1000);
    },

    successSwitch(text) {
      this.searchPromocode();
      this.openModalAlertText(text);
    },
    goPromocodeDetail(item) {
      this.$router.push(`/promocode/details/0?type=${item.id}`);
    },
    filterPage(obj) {
      let { page } = obj;
      this.filter.skip = page;

      this.searchPromocode();
    },
    searchAll(filter) {
      if (filter.filter.eventId) {
        filter.filter.eventId = filter.filter.eventId.toString();
      } else {
        filter.filter.eventId = null;
      }

      this.searchPromocode();
      this.closeSideBar();
    },
    clearFilter() {
      this.filter = {
        filter: {
          eventId: null,
          promocodeType: null,
          startDateEndDate: {
            startTime: "",
            endTime: "",
          },
          isActive: null,
        },
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      };

      this.searchPromocode();
      this.$refs.autoCompleteEventId.clearInput();
    },

    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },

    openSideBar() {
      this.$refs.sidebarPromocode.show();
      this.getEventOption();
    },
    closeSideBar() {
      this.$refs.sidebarPromocode.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.promocode {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  .right {
    float: right;
  }
}

// ! override
.g-form {
  width: 100%;
}

::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}
</style>
